//
// Form Wizard
//

// twitter-bs-wizard

.twitter-bs-wizard {
    .twitter-bs-wizard-nav {
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $gray-300;
            position: absolute;
            left: 0;
            top: 26px;
        }

        .step-number {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 34px;
            border: 2px solid $primary;
            color: $primary;
            text-align: center;
            border-radius: 50%;
            position: relative;
            background-color: $card-bg;

            @media (max-width: 991.98px) {
                display: block;
                margin: 0 auto 8px !important;
            }
        }

        .nav-link {
            .step-title {
                display: block;
                margin-top: 8px;
                font-weight: $font-weight-bold;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            &.active {
                background-color: transparent;
                color: $gray-700;

                .step-number {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    .twitter-bs-wizard-pager-link {
        padding-top: 24px;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;

        li {
            display: inline-block;

            a {
                display: inline-block;
                padding: 0.47rem 0.75rem;
                background-color: $primary;
                color: $white;
                border-radius: 0.25rem;
            }

            &.disabled {
                a {
                    cursor: not-allowed;
                    background-color: lighten($primary, 8%);
                }
            }

            &.next {
                float: right;
            }
        }
    }
}

.twitter-bs-wizard-tab-content {
    padding-top: 24px;
    min-height: 262px;
}
