//
// Form-Upload
//

/* Dropzone */
.dropzone {
    min-height: 230px;
    border: 2px dashed $gray-400;
    background: $card-bg;
    border-radius: 6px;

    .dz-message {
        font-size: 24px;
        width: 100%;
    }
}
