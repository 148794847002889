/*
Template Name: Nazox - Responsive Bootstrap 4 Admin Dashboard
Author: Themesdesign
Contact: themesdesign.in@gmail.com
File: Main Css File
*/

/* TABLES OF CSS

1. General
2. Topbar
3. Page Header
4. Footer
5. Right Sidebar
6. Vertical
7. Horizontal Nav
8. Layouts
9. Waves
10. Avatar
11. Helper
12. Demos
13. Plugins
14. Authentication
15. Email
16. Coming Soon
17. Timeline
18. Extra Pages

*/

//Fonts
@import 'custom/fonts/fonts';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/vertical';
@import 'custom/structure/horizontal-nav';
@import 'custom/structure/layouts';

// Components
@import 'custom/components/waves';
@import 'custom/components/avatar';
@import 'custom/components/accordion';
@import 'custom/components/helper';
@import 'custom/components/preloader';
@import 'custom/components/forms';
@import 'custom/components/widgets';
@import 'custom/components/demos';
@import 'custom/components/print';

// Plugins
@import 'custom/plugins/custom-scrollbar';
@import 'custom/plugins/calendar';
@import 'custom/plugins/session-timeout';
@import 'custom/plugins/round-slider';
@import 'custom/plugins/range-slider';
@import 'custom/plugins/sweatalert2';
@import 'custom/plugins/rating';
@import 'custom/plugins/toastr';
@import 'custom/plugins/parsley';
@import 'custom/plugins/select2';
@import 'custom/plugins/switch';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/datatable';
@import 'custom/plugins/form-editors';
@import 'custom/plugins/form-upload';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/table-editable';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/flot';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/google-map';
@import 'custom/plugins/vector-maps';

// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/ecommerce';
@import 'custom/pages/email';
@import 'custom/pages/chat';
@import 'custom/pages/kanbanboard';
@import 'custom/pages/coming-soon';
@import 'custom/pages/timeline';
@import 'custom/pages/extras-pages';

.is-invalid ~ .css-2b097c-container .css-yk16xz-control {
    border-color: $danger;
}

.dropzone {
    position: relative;
    display: flex;
    flex-direction: column;

    &.dropzone-single.dz-max-files-reached {
        &:hover {
            .dz-message {
                opacity: 1;
            }
        }
    }
}

.dz-preview-single {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.375rem;
}

.dz-preview-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.375rem;
}

.dz-preview-img {
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    -o-object-fit: cover;
    object-fit: cover;
}

.dz-message {
    z-index: 999;
    padding: 5rem 1rem;
    cursor: pointer;
    transition: all 0.15s ease;
    text-align: center;
    color: #8898aa;
    border: 1px dashed #dee2e6;
    border-radius: 0.375rem;
    background-color: #fff;
    order: -1;
}

.dropzone-single.dz-max-files-reached .dz-message {
    opacity: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
}
