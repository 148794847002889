.tree--hide::after {
    position: absolute;
    right: 0;
    top: 4px;
    content: '';
    display: block;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 10px solid #000;
    border-bottom: 5px solid transparent;
}

.tree--open::after {
    position: absolute;
    right: 0;
    top: 4px;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #000;
}

.tree .hide {
    display: none;
}

.group-checkbox {
    padding: 10px;
}

.tree--children {
    box-shadow: 0 1px 1px rgb(68 0 255 / 30%);
    margin-bottom: 10px;
}
