//
// timeline.scss
//

/************** vertical timeline **************/

.timeline {
    position: relative;
    margin-bottom: 30px;
    &::before {
        content: '';
        position: absolute;
        width: 3px;
        top: 30px;
        left: 0;
        bottom: 0;
        background-color: darken($gray-300, 4%);
    }

    .timeline-item {
        display: flex;
    }

    .timeline-block {
        width: 100%;
    }

    .time-show-btn {
        margin-bottom: 30px;
    }

    .timeline-box {
        margin: 20px 0;
        position: relative;
        margin-left: 45px;
    }

    .timeline-date {
        display: inline-block;
        padding: 4px 16px 4px 8px;
        border-radius: 0 30px 30px 0;
        background-color: $primary;
        color: $white;
        position: relative;
        left: -30px;

        .circle-dot {
            margin-right: 8px;
        }
    }

    .timeline-icon {
        position: absolute;
        width: 10px;
        height: 10px;
        background: $card-bg;
        border-radius: 50%;
        display: block;
        border: 2px solid $primary;
        left: -48px;
        text-align: center;
        top: 27px;
        z-index: 9;
        &::before {
            content: '';
            position: absolute;
            height: 3px;
            width: 20px;
            background-color: darken($gray-300, 4%);
            left: 12px;
            top: 3px;
        }
    }

    .timeline-album {
        margin-top: 16px;
        a {
            display: inline-block;
            margin-right: 5px;
        }
        img {
            height: 40px;
            width: auto;
            border-radius: 4px;
        }
    }
}

@media (min-width: 768px) {
    .timeline {
        .timeline-block {
            width: 50%;
        }

        &::before {
            left: 50%;
        }
        .time-show-btn {
            position: relative;
            left: 67px;
            text-align: right;
        }
    }

    .timeline-item {
        &::before {
            content: '';
            display: block;
            width: 50%;
        }

        &.timeline-left {
            text-align: right;
            &::after {
                content: '';
                display: block;
                width: 50%;
            }

            &::before {
                display: none;
            }

            .timeline-box {
                margin-left: 0;
                margin-right: 45px;
            }

            .timeline-date {
                padding: 4px 8px 4px 16px;
                border-radius: 30px 0 0 30px;
                left: auto;
                right: -30px;

                .circle-dot {
                    float: right;
                    margin-right: 0;
                    margin-left: 8px;
                }
            }

            .timeline-icon {
                left: auto;
                right: -52px;
                &::before {
                    left: auto;
                    right: 12px;
                }
            }
        }
    }
}

.left-timeline {
    &::before {
        left: 3px;
    }
    .timeline-item::before {
        display: none;
    }

    .timeline-block {
        width: 100%;
    }

    .timeline-icon {
        left: -45px;
    }
}
