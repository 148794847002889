//
// ecommerce.scss
//

// product

.search-box {
    .form-control {
        border-radius: 30px;
        padding-left: 40px;
    }
    .search-icon {
        font-size: 16px;
        position: absolute;
        left: 13px;
        top: 0;
        line-height: 38px;
    }
}

.categories-group-card {
    border-bottom: 1px solid $border-color;
}

.categories-group-list {
    display: block;
    color: $dark;
    font-weight: $font-weight-medium;
    padding: 8px 16px;

    &[aria-expanded='true'] {
        background-color: $gray-300;
    }

    &:last-child {
        border: 0;
    }

    &:hover {
        color: $dark;
    }
}

.categories-list {
    padding: 8px 0;
    li {
        a {
            display: block;
            padding: 4px 16px;
            color: $body-color;
        }

        &.active {
            a {
                color: $primary;
            }
        }
    }
}

// Product Details

.product-detai-imgs {
    .nav {
        .nav-link {
            margin: 7px 0;

            &.active {
                background-color: $gray-300;
            }
        }
    }
}

.product-color {
    a {
        display: inline-block;
        text-align: center;
        color: $body-color;

        .product-color-item {
            margin: 7px;
            border: 2px solid $border-color;
            border-radius: 4px;
        }
        &.active,
        &:hover {
            color: $primary;
            .product-color-item {
                border-color: $primary !important;
            }
        }
    }
}

.ecommerce-sortby-list {
    li {
        color: $dark;
        a {
            color: $body-color;
            padding: 4px;
        }

        &.active {
            a {
                color: $primary;
            }
        }
    }
}

.product-box {
    padding: 24px;
    border: 1px solid $border-color;
    transition: all 0.4s;

    &:hover {
        box-shadow: $box-shadow;
    }
}

.product-img {
    position: relative;

    .product-ribbon {
        position: absolute;
        top: 0;
        left: -24px;
        padding: 6px 8px;
        border-radius: 0 30px 30px 0;
    }

    .product-like {
        position: absolute;
        top: 0;
        right: 0;
        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            border: 2px solid $gray-300;
            line-height: 38px;
            border-radius: 50%;
            text-align: center;
            color: $gray-500;
        }
    }
}

.product-detail {
    .nav-pills {
        .nav-link {
            margin-bottom: 7px;
            &.active {
                background-color: $gray-300;
            }

            .tab-img {
                width: 5rem;
            }
        }
    }

    .product-img {
        border: 1px solid $border-color;
        padding: 24px;
    }
}

.product-desc-list {
    li {
        padding: 4px 0;
    }
}

.product-review-link {
    .list-inline-item {
        a {
            color: $gray-600;
        }
        &:not(:last-child) {
            margin-right: 14px;
        }
    }
}

// ecommerce cart

.product-cart-touchspin {
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    border-radius: $border-radius;
    .form-control {
        border-color: transparent;
        height: 32px;
    }

    .input-group-btn .btn {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $primary !important;
        font-size: 16px;
        padding: 3px 12px;
        box-shadow: none;
    }
}

// ecommerce checkout

.shipping-address {
    box-shadow: none;
    &.active {
        border-color: $primary !important;
    }
}
