.scheduler-table .dataTables_wrapper .row .col-sm-12 .dataTables_paginate {
    display: none;
}

.upload-files ul.error {
    list-style: none;
    padding-left: 5px;
    margin-bottom: 10px;
}

.upload-files ul.error span.error-title {
    color: #f1734f;
}
